<template>
    <b-row>
        <!-- <b-form class="col-12"  v-on:submit.prevent > -->
        
        <b-col cols="12" v-if="readAloudQuestList!=null" >
            <h5>Read Aloud Questions</h5>
            <p class="questHeading">Please add 6 to 7 questions.</p>
            <b-row v-for="(item,index) in selectedReadAloudQuest" :key="'rd'+index" :id="'rd'+index" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedReadAloudQuest[index]" :options="readAloudQuestList" @change="changeReadAloud(selectedReadAloudQuest[index],index)" value-field="readaloud_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'readAloud-'+index" cols="9" >
                    {{selectedReadAloudValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12"  v-if="repeateSentenceQuestList!=null">
            <h5>Repeat Sentence Questions</h5>
            <p class="questHeading">Please add 10 to 12 questions.</p>
           <b-row v-for="(item,index) in selectedRepeateSentenceQuest" :key="item[index]" :id="index"   class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedRepeateSentenceQuest[index]" :options="repeateSentenceQuestList" @change="changeRepeateSentence(selectedRepeateSentenceQuest[index],index)" value-field="recording_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'repeatSentence-'+index" cols="9">
                    {{selectedRepeateSentenceValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12"  v-if="describeImageQuestList!=null">
            <h5>Describe Image Questions</h5>
            <p class="questHeading">Please add 3 to 4 questions.</p>
            <b-row v-for="(item,index) in selectedDescribeImageQuest" :key="item[index]" :id="index"   class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedDescribeImageQuest[index]" :options="describeImageQuestList" @change="changeDescribeImage(selectedDescribeImageQuest[index],index)"  value-field="graph_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'describeImage-'+index" cols="9">
                    {{selectedDescribeImageValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="reTellLectureQuestList!=null">
            <h5>Re-Tell Lecture Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedReTellLectureQuest" :key="item[index]" :id="index"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedReTellLectureQuest[index]" :options="reTellLectureQuestList" @change="changeReTellLecture(selectedReTellLectureQuest[index],index)" value-field="lecture_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'reTellLecture-'+index" cols="9">
                    {{selectedReTellLectureValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="answerShortQuestList!=null">
            <h5>Answer Short Questions</h5>
            <p class="questHeading">Please add 5 to 6 questions.</p>
            <b-row v-for="(item,index) in selectedAnswerShortQuest" :key="item[index]" :id="index"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedAnswerShortQuest[index]" :options="answerShortQuestList" @change="changeAnswerShort(selectedAnswerShortQuest[index],index)" value-field="short_question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'answerShort-'+index" cols="9">
                    {{selectedAnswerShortValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="errorSelection!=''">
            <p class="pre-formatted text-danger mb-5">{{errorSelection}}</p>
        </b-col>
        <div class="col-6 displayInline" align="right">
            <b-button type="button" variant="primary" @click="onSubmit">{{buttonText}}</b-button>
        </div>
        <!-- <div class="col-2 displayInline" align="left">
            <b-button type="button" @click="reset()" variant="danger">Clear All</b-button>
        </div> -->
        <!-- <div class="col-2 displayInline" align="left">
            <b-button type="button" @click="clearAll()" variant="danger">Clear All</b-button>
        </div> -->
        <!-- </b-form> -->
    </b-row>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    data(){
        return{
            readAloudQuestList:null,
            allmockQuestions:null,
            // [{
            //     readaloud_id: "",
            //     readaloud_text: "Please add 6 to 7 questions.",
            //     title: "Please Select Question."}],
            repeateSentenceQuestList:null,
            describeImageQuestList:null,
            reTellLectureQuestList:null,
            answerShortQuestList:null,
            selectedReadAloudQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',
            },
            selectedRepeateSentenceQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',id8:'',id9:'',id10:'',id11:'',id12:''
            },
            selectedDescribeImageQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',
            },
            selectedReTellLectureQuest:{
                id1:'',id2:'',id3:'',id4:''
            },
            selectedAnswerShortQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',id8:'',id9:'',id10:'',id11:'',id12:''
            },
            selectedReadAloudValue:{
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',
            },
            selectedRepeateSentenceValue:{
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',val8:'',val9:'',val10:'',val11:'',val12:''
            },
            selectedDescribeImageValue:{
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',
            },
            selectedReTellLectureValue:{
                val1:'',val2:'',val3:'',val4:''
            },
            selectedAnswerShortValue:{
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',val8:'',val9:'',val10:'',val11:'',val12:''
            },
            buttonText: 'Save Speaking Module & Next',
            questminimumValues:{
                readAloudMin:6, repeatSentenceMin:10, describeImageMin: 3, reTellLectureMin: 1, answerShortMin: 5
            },
            errorSelection:''
        }
    },
    methods:{
        ...mapActions({
            mockQuestionService: 'mocktest/fetchMockQuestion',
            saveMockTest:'mocktest/saveMockTest',
        }),
        ...mapGetters({
            getMockQuestionList: 'mocktest/mockQuestions',
            getResponse: 'mocktest/QuestionResponse'
        }),
        changeReadAloud(questionId,index){
            var selectedQuest=this.readAloudQuestList.filter(function (item) {
                    return item.readaloud_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                ////console.log(index)
                this.selectedReadAloudValue[index]=selectedQuest.readaloud_text
                index =$.isNumeric(index)? 'id'+index:index;
                $('#readAloud-'+index).text(selectedQuest.readaloud_text)
                 const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='readaloud'
            });
            if(checkQuest.length>0) $('#readAloud-'+index).addClass('isRepeated')
            else $('#readAloud-'+index).removeClass('isRepeated')
                // ////console.log(this.selectedReadAloudValue[index])
                // ////console.log(this.selectedReadAloudValue[index])
                //this.$forceUpdate();
        },
        changeRepeateSentence(questionId,index){
            var selectedQuest=this.repeateSentenceQuestList.filter(function (item) {
                    return item.recording_id == questionId;
                })[0];
                this.selectedRepeateSentenceValue[index]=selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#repeatSentence-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='repeatsentence'
                });
                if(checkQuest.length>0) $('#repeatSentence-'+index).addClass('isRepeated')
                else $('#repeatSentence-'+index).removeClass('isRepeated')
                //////console.log(selectedQuest)
                
        },
        changeDescribeImage(questionId,index){
            var selectedQuest=this.describeImageQuestList.filter(function (item) {
                    return item.graph_id == questionId;
                })[0];
                this.selectedDescribeImageValue[index] = selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#describeImage-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='describeimage'
                });
                if(checkQuest.length>0) $('#describeImage-'+index).addClass('isRepeated')
                else $('#describeImage-'+index).removeClass('isRepeated')
                //////console.log(selectedQuest)
                
        },
        changeReTellLecture(questionId,index){
            var selectedQuest=this.reTellLectureQuestList.filter(function (item) {
                    return item.lecture_id == questionId;
                })[0];
                this.selectedReTellLectureValue[index] = selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#reTellLecture-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='retellLecture'
                });
                if(checkQuest.length>0) $('#reTellLecture-'+index).addClass('isRepeated')
                else $('#reTellLecture-'+index).removeClass('isRepeated')
                //////console.log(selectedQuest)
                
        },
        changeAnswerShort(questionId,index){
            var selectedQuest=this.answerShortQuestList.filter(function (item) {
                    return item.short_question_id == questionId;
                })[0];
                this.selectedAnswerShortValue[index] = selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#answerShort-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='answerShortQuestion'
                });
                if(checkQuest.length>0) $('#answerShort-'+index).addClass('isRepeated')
                else $('#answerShort-'+index).removeClass('isRepeated')
                //////console.log(selectedQuest)
                
        },
        isSet(val) {
            if ((val != undefined) && (val != '')){
                return true;
            }
            return false;
        },
        Validate(obj,max) {
            if(this.isSet(obj)) {
                if (obj.length && obj.length > 0) { 
                    return false;
                }
                var counter=1;
                var flag=false
                for (var key in obj) {
                    //counter++;
                    if (hasOwnProperty.call(obj, key)) {
                        if(obj[key] == '' & counter <= max ) 
                        {
                            flag= false;
                        }
                        else if(counter <= max){
                            counter++;
                            flag =true
                        }
                    }
                }
                }
                return flag;    
            },
        onSubmit(){
            this.errorSelection =''
            //////console.log(this.$store.state.mock_type)
            if(this.$store.state.mock_title == '' | this.$store.state.mock_type == '0' | this.$store.state.mock_title == undefined | this.$store.state.mock_type == undefined){
                this.errorSelection +='Please Enter Mock Test Name and select Type before saving.\n'
            }
            var readAloud =this.selectedReadAloudQuest
            //////console.log(readAloud.length)
            if(!this.Validate(readAloud, this.questminimumValues.readAloudMin )){
                this.errorSelection +='Please select the minimum Read Aloud question Limit: '+ this.questminimumValues.readAloudMin+'.\n'
                //////console.log(this.errorSelection)
            }

            var repeatSentence = this.selectedRepeateSentenceQuest
            if(!this.Validate(repeatSentence,this.questminimumValues.repeatSentenceMin)){
                this.errorSelection +='Please select the minimum Repeat Sentence question Limit: '+ this.questminimumValues.repeatSentenceMin+'.\n'
            }

            var describeImage = this.selectedDescribeImageQuest
            if(!this.Validate(describeImage,this.questminimumValues.describeImageMin)){
                this.errorSelection +='Please select the minimum Describe Image question Limit: '+ this.questminimumValues.describeImageMin+'.\n'
            }

            var reTellLecture = this.selectedReTellLectureQuest
            if(!this.Validate(reTellLecture, this.questminimumValues.reTellLectureMin)){
                this.errorSelection +='Please select the minimum Re Tell Lecture question Limit: '+ this.questminimumValues.reTellLectureMin+'.\n'
            }

            var answerShortQuestion = this.selectedAnswerShortQuest
            if(!this.Validate(answerShortQuestion, this.questminimumValues.answerShortMin)){
                this.errorSelection +='Please select the minimum Answer Short Question question Limit: '+ this.questminimumValues.answerShortMin+'.\n'
            }
            if(this.errorSelection==''){ //need to uncomment after testing
                var questSpeakingSelected={
                                    readQuest: readAloud,
                                    readValues: this.selectedReadAloudValue,
                                    qustType1:'readaloud',
                                    repeatSentenceQuest: repeatSentence,
                                    repeatSentenceValues: this.selectedRepeateSentenceValue,
                                    questType2:'repeatsentence',
                                    describeImageQuest: describeImage,
                                    descibeImageValues:this.selectedDescribeImageValue,
                                    questType3: 'describeimage',
                                    reTellLectureQuest: reTellLecture,
                                    reTellLectureValues: this.selectedReTellLectureValue,
                                    questType4: 'retellLecture',
                                    answershortQuest: answerShortQuestion,
                                    answerShortQuestionValues: this.selectedAnswerShortValue,
                                    questType5: 'answerShortQuestion'
                                }
                //////console.log(questSpeakingSelected);
                this.$store.state.mock_module = 'writing'
                //////console.log(this.$store.state.mock_module)
                this.$ls.set('speakingSelected',questSpeakingSelected)
                this.$parent.writingStart = true
                this.$parent.writingShow = true
                this.$parent.speakingShow = false
                this.$parent.step= 2
                // this.saveMockTest(questSpeakingSelected).then(()=>{
                //                 ////console.log(this.getResponse())
                //                 this.$store.state.mock_test_id = this.getResponse().data
                //                 this.$store.state.mock_module = 'writing'
                //                 //$('ul.nav-tabs li:nth-child(2) a').className = 'nav-link text-secondary'
                //                 this.questSpeakingSelected = {};
                //                 //alert('Data has been saved successfully.');
                //             });

            }
        },
        loadDropDowns(){
            this.mockQuestionService('getAllSpeakingMockQ/getAll').then((res)=>{
                if(res =='success')
                {
                    ////console.log('LOADING SPEAKING')
                    this.$store.state.mock_module = 'speaking'
                    //////console.log(this.getMockQuestionList())
                    var allQuestions= this.getMockQuestionList().data
                    //////console.log(allQuestions)
                    //////console.log(allQuestions.readAloud)
                    //this.readAloudQuestList.pop([{readaloud_id: "", readaloud_text: "Please add 6 to 7 questions.", title: "Please Select Question."}])
                    
                    //this.readAloudQuestList =$.extend(true,[{'readaloud_id': "", 'readaloud_text': "Please add 6 to 7 questions.", 'title': "Please Select Question."}],allQuestions.readAloud)
                    
                    this.readAloudQuestList= allQuestions.readAloud
                    var dumy= {readaloud_id: "", readaloud_text: "", title: "Please Select Question."}
                    this.readAloudQuestList.splice(0, 0, dumy);
                    
                    this.repeateSentenceQuestList = allQuestions.repeatSentence
                    ////console.log(this.repeateSentenceQuestList)
                     dumy= {recording_id: "", transcript: "", title: "Please Select Question."}
                    this.repeateSentenceQuestList.splice(0, 0, dumy);

                    this.describeImageQuestList = allQuestions.describeImage
                     dumy= {graph_id: "", transcript: "", title: "Please Select Question."}
                    this.describeImageQuestList.splice(0, 0, dumy);

                    this.reTellLectureQuestList = allQuestions.retellLecture
                     dumy= {lecture_id: "", transcript: "", title: "Please Select Question."}
                     this.reTellLectureQuestList.splice(0, 0, dumy);

                    this.answerShortQuestList = allQuestions.shortQuestion
                     dumy= {short_question_id: "", transcript: "", title: "Please Select Question."}
                     //this.answerShortQuestList.splice(0, 0, dumy);
                     this.answerShortQuestList.splice(0, 0, dumy);

                }
            }).then(()=>{
                var alreadySelected = this.$ls.get('speakingSelected')
                if(alreadySelected != undefined & alreadySelected != null )
                {
                    this.selectedReadAloudQuest = alreadySelected.readQuest
                    this.selectedRepeateSentenceQuest = alreadySelected.repeatSentenceQuest
                    this.selectedDescribeImageQuest = alreadySelected.describeImageQuest
                    this.selectedReTellLectureQuest = alreadySelected.reTellLectureQuest
                    this.selectedAnswerShortQuest = alreadySelected.answershortQuest

                    this.selectedReadAloudValue = alreadySelected.readValues
                    this.selectedRepeateSentenceValue = alreadySelected.repeatSentenceValues
                    this.selectedDescribeImageValue = alreadySelected.descibeImageValues
                    this.selectedReTellLectureValue = alreadySelected.reTellLectureValues
                    this.selectedAnswerShortValue = alreadySelected.answerShortQuestionValues
                }
                else if(this.$parent.getAllQuestionList != null){
                    this.populateData(this.$parent.getAllQuestionList)
                }
            })
        },
        loadAllMockQuestions(){
            var vm=this
            this.mockQuestionService('mocktest/allmockquest').then(()=>{
                ////debugger
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
            });
        },
        reset(){
            this.selectedReadAloudQuest = { id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:''};
            this.selectedRepeateSentenceQuest={
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',id8:'',id9:'',id10:'',id11:'',id12:''
            };
            this.selectedDescribeImageQuest={
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',
            };
            this.selectedReTellLectureQuest={
                id1:'',id2:'',id3:'',id4:''
            };
            this.selectedAnswerShortQuest={
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:'',id7:'',id8:'',id9:'',id10:'',id11:'',id12:''
            };
            this.selectedReadAloudValue={
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',
            };
            this.selectedRepeateSentenceValue={
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',val8:'',val9:'',val10:'',val11:'',val12:''
            }
            this.selectedDescribeImageValue={
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',
            }
            this.selectedReTellLectureValue={
                val1:'',val2:'',val3:'',val4:''
            }
            this.selectedAnswerShortValue={
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:'',val7:'',val8:'',val9:'',val10:'',val11:'',val12:''
            }
            this.$forceUpdate()
        },
        clearAll(){
            if(this.$ls.get('speakingSelected') != null) this.$ls.remove('speakingSelected')

            if(this.$ls.get('writingSelected') != null) this.$ls.remove('writingSelected')

            if(this.$ls.get('readingSelected') != null) this.$ls.remove('readingSelected')

            if(this.$ls.get('listeningSelected') != null) this.$ls.remove('listeningSelected')

            this.reset()

        },
        filterArry(question_type,array){
            return array.filter(function (item) {
                                return item.question_module == question_type;
                            });
        },
        changeValues(selectedArray,newArray, key,changeEvent){
            var count =0;
                for (var item in selectedArray) {
                    if(count < newArray.length) {
                        //////console.log(newArray[count])
                        selectedArray[item] = newArray[count][key]
                        changeEvent(newArray[count][key],count+1)
                        count++
                    }
                }
        },
        populateData(questionArray){
                //var questionArray = this.$parent.questionArray
                ////console.log('populate called');
                ////console.log(questionArray);
                var tempArray = this.filterArry('readaloud',questionArray);
                this.changeValues(this.selectedReadAloudQuest,tempArray,'question_id',this.changeReadAloud);

                tempArray = this.filterArry('retellLecture',questionArray);
                this.changeValues(this.selectedReTellLectureQuest,tempArray,'question_id',this.changeReTellLecture);

                tempArray = this.filterArry('answerShortQuestion',questionArray);
                this.changeValues(this.selectedAnswerShortQuest,tempArray,'question_id',this.changeAnswerShort);

                tempArray = this.filterArry('repeatsentence',questionArray);
                this.changeValues(this.selectedRepeateSentenceQuest,tempArray,'question_id',this.changeRepeateSentence);

                tempArray = this.filterArry('describeimage',questionArray);
                this.changeValues(this.selectedDescribeImageQuest,tempArray,'question_id',this.changeDescribeImage);

                
                // tempArray = this.filterArry('retellLecture',questionArray);
                // ////console.log(tempArray);
                // this.changeValues(this.selectedReTellLectureQuest,tempArray,'question_id',this.changeReTellLecture);

                

        }
    },
    beforeMount(){
        var vm=this
            this.mockQuestionService('mocktest/allmockquest').then(()=>{
                ////debugger
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
                    this.loadDropDowns()
            });
        // this.loadDropDowns()
        // this.loadAllMockQuestions()
    },
    mounted(){
        
    }
}
</script>
<style scoped>
.questHeading {
    padding-bottom: 1rem!important;
    margin-bottom: 1rem!important;
}
.col-3
{
    border-right: 1px solid #000;
}
</style>
<style>
.isRepeated{
    color: tomato;
}
</style>