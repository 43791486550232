<template>
     <b-row>
         <!-- <b-form class="col-12"  v-on:submit.prevent="onSubmit" > -->
        
         <b-col cols="12" v-if="summarizeSpokenTextQList!=null">
            <h5>Summarized Spoken Text Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedSummarizeSpokenTextQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedSummarizeSpokenTextQ[index]" :options="summarizeSpokenTextQList" @change="changeSummarizeSpokenText(selectedSummarizeSpokenTextQ[index],index)" value-field="question_id"
                        text-field="question_title"></b-form-select>
                </b-col>
                <b-col :id="'summarizedSpoken-'+index" cols="9">
                    {{selectedSummarizeSpokenTextValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="mutlipleChoiceMultipleQList!=null">
            <h5>Multiple Choice (Multiple) Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedMutlipleChoiceMultipleQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedMutlipleChoiceMultipleQ[index]" :options="mutlipleChoiceMultipleQList" @change="changeMutlipleChoiceMultiple(selectedMutlipleChoiceMultipleQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'listMcqMultiple-'+index" cols="9">
                    {{selectedMutlipleChoiceMultipleValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="fillinBlanksQList!=null">
            <h5>Listening Fill in Blanks Questions</h5>
            <p class="questHeading">Please add 2 to 3 questions.</p>
            <b-row v-for="(item,index) in selectedFillinBlanksQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedFillinBlanksQ[index]" :options="fillinBlanksQList" @change="changeFillinBlanks(selectedFillinBlanksQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'listFill-'+index" cols="9">
                    {{selectedFillinBlanksValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="highlightCorrectSummaryQList!=null">
            <h5>Highlight Correct Summary Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedCorrectSummaryQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedCorrectSummaryQ[index]" :options="highlightCorrectSummaryQList" @change="changeHighLightCorrectSum(selectedCorrectSummaryQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'highlightCorrectSum-'+index" cols="9">
                    {{selectedHighLightCorrectSummaryValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="mutlipleChoiceSingleQList!=null">
            <h5>Multiple Choice (Single) Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedMutlipleSingleQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedMutlipleSingleQ[index]" :options="mutlipleChoiceSingleQList" @change="changeMutlipleChoiceSingle(selectedMutlipleSingleQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'listMcqSingle-'+index" cols="9">
                    {{selectedListenMutlipleChoiceSingleValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="missingWordsQList!=null">
            <h5>Select Missing Word Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedMissingWordsQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedMissingWordsQ[index]" :options="missingWordsQList" @change="changeSelectMissingWords(selectedMissingWordsQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'selectMissing-'+index" cols="9">
                    {{selectedMissingWordsValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="highlightInCorrectWordsQList!=null">
            <h5>Highlight Incorrect Words Questions</h5>
            <p class="questHeading">Please add 2 to 3 questions.</p>
            <b-row v-for="(item,index) in selectedInCorrectWordsQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedInCorrectWordsQ[index]" :options="highlightInCorrectWordsQList" @change="changeHighlightInCorrectWords(selectedInCorrectWordsQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'highlightIncorrectWords-'+index" cols="9">
                    {{selectedInCorrectWordsValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="writeFromDectationQList!=null">
            <h5>Write from Dictation Questions</h5>
            <p class="questHeading">Please add 3 to 4 questions.</p>
            <b-row v-for="(item,index) in selectedWriteFromDectationQ" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedWriteFromDectationQ[index]" :options="writeFromDectationQList" @change="changeWriteFromDectation(selectedWriteFromDectationQ[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'writeDict-'+index" cols="9">
                    {{selectedWriteFromDectationValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="errorSelection!=''">
            <p class="pre-formatted text-danger mb-5">{{errorSelection}}</p>
        </b-col>
         <div class="col-6 displayInline" align="right">
            <b-button type="button" variant="primary" @click="onSubmit()">{{buttonText}}</b-button>
        </div>
        <!-- <div class="col-2 displayInline" align="center">
            <b-button type="button" @click="reset()" variant="danger">Reset All</b-button>
        </div> -->
        <div class="col-2 displayInline" align="left">
            <b-button type="button" @click="back()" variant="outline-warning">Back</b-button>
        </div>
        <!-- </b-form> -->
    </b-row>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    data(){
        return{
            test:'',
            highlightCorrectSummaryQList: null,
            highlightInCorrectWordsQList: null,
            mutlipleChoiceSingleQList: null,
            mutlipleChoiceMultipleQList: null,
            fillinBlanksQList: null,
            missingWordsQList: null,
            summarizeSpokenTextQList: null,
            writeFromDectationQList:null,
            selectedInCorrectWordsQ:{
                id1:'',id2:'',id3:''
            },
            selectedCorrectSummaryQ:{
                id1:'',id2:'',id3:''
            },
            selectedMutlipleSingleQ:{
                id1:'',id2:'',id3:''
            },
            selectedMutlipleChoiceMultipleQ:{
                id1:'',id2:'',id3:''
            },
            selectedFillinBlanksQ:{
                id1:'',id2:'',id3:''
            },
            selectedMissingWordsQ:{
                id1:'',id2:'',id3:''
            },
            selectedSummarizeSpokenTextQ:{
                id1:'',id2:'',id3:''
            },
            selectedWriteFromDectationQ:{
                id1:'',id2:'',id3:'',id4:''
            },
            selectedwriteFromDectationQList:{
                id1:'',id2:'',id3:'',id4:''
            },

            selectedHighLightCorrectSummaryValue:{
                val1:'',val2:'',val3:''
            },
            selectedInCorrectWordsValue:{
                val1:'',val2:'',val3:''
            },
            selectedListenMutlipleChoiceSingleValue:{
                val1:'',val2:'',val3:''
            },
            selectedMutlipleChoiceMultipleValue:{
                val1:'',val2:'',val3:''
            },
            selectedFillinBlanksValue:{
                val1:'',val2:'',val3:''
            },
            selectedMissingWordsValue:{
                val1:'',val2:'',val3:''
            },
            selectedSummarizeSpokenTextValue:{
                val1:'',val2:'',val3:''
            },
            selectedWriteFromDectationValue:{
                id1:'',id2:'',id3:'',id4:''
            },
            buttonText: 'Save & Complete Mock',
            questminimumValues:{
                highLightCorrectSummaryMin:1, inCorrectWordsMin:2, mcqSingleMin: 1, mcqMultipleMin: 1, fillBlanksMin: 2, 
                missingWordsMin:1, summarizedSpokenTextMin: 1, writeFromDictationMin: 3
            },
            errorSelection:'',
            allmockQuestions:null
        }
    },
    methods:{
        ...mapActions({
            mockQuestionService: 'mocktest/fetchMockQuestion',
            saveMockTest:'mocktest/saveMockTest',
        }),
        ...mapGetters({
            getMockQuestionList: 'mocktest/mockQuestions',
            getResponse: 'mocktest/QuestionResponse'
        }),
        changeHighLightCorrectSum(questionId,index){ //1
        ////debugger
            var selectedQuest=this.highlightCorrectSummaryQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                //console.log(selectedQuest)
                this.selectedHighLightCorrectSummaryValue[index]=selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#highlightCorrectSum-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='highLightSummary'
                });
                if(checkQuest.length>0) $('#highlightCorrectSum-'+index).addClass('isRepeated')
                else $('#highlightCorrectSum-'+index).removeClass('isRepeated')
        },
        changeHighlightInCorrectWords(questionId,index){ //2
                ////console.log(questionId)
            var selectedQuest=this.highlightInCorrectWordsQList.filter(function (item) {
                ////console.log(item)
                    return item.question_id == questionId;
                })[0];
                //console.log(selectedQuest)
                this.selectedInCorrectWordsValue[index]=selectedQuest.question_para
                index =$.isNumeric(index)? 'id'+index:index;
                $('#highlightIncorrectWords-'+index).text(selectedQuest.question_para)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='highLightWords'
                });
                if(checkQuest.length>0) $('#highlightIncorrectWords-'+index).addClass('isRepeated')
                else $('#highlightIncorrectWords-'+index).removeClass('isRepeated')
        },
        changeMutlipleChoiceSingle(questionId,index){ //3
        ////console.log(this.mutlipleChoiceSingleQList)
        ////console.log(questionId)
            var selectedQuest=this.mutlipleChoiceSingleQList.filter(function (item) {
                ////console.log(item)
                    return item.question_id == questionId;
                })[0];
                //console.log(selectedQuest)
                this.selectedListenMutlipleChoiceSingleValue[index] = selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                //console.log(selectedQuest.question_desc)
                //console.log($('#listMcqSingle-'+index))
                $('#listMcqSingle-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='listMcqSingle'
                });
                if(checkQuest.length>0) $('#listMcqSingle-'+index).addClass('isRepeated')
                else $('#listMcqSingle-'+index).removeClass('isRepeated')
        },
        changeMutlipleChoiceMultiple(questionId,index){ //4
            var selectedQuest=this.mutlipleChoiceMultipleQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedMutlipleChoiceMultipleValue[index] = selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                //console.log(selectedQuest.question_desc)
                //console.log($('#listMcqMultiple-'+index))
                $('#listMcqMultiple-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='listMcqMultiple'
                });
                if(checkQuest.length>0) $('#listMcqMultiple-'+index).addClass('isRepeated')
                else $('#listMcqMultiple-'+index).removeClass('isRepeated')
        },
        changeFillinBlanks(questionId,index){ //5
            var selectedQuest=this.fillinBlanksQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedFillinBlanksValue[index] = selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#listFill-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='listFillBlanks'
                });
                if(checkQuest.length>0) $('#listFill-'+index).addClass('isRepeated')
                else $('#listFill-'+index).removeClass('isRepeated')
        },
        changeSelectMissingWords(questionId,index){ //6
            var selectedQuest=this.missingWordsQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedMissingWordsValue[index] = selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#selectMissing-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='selectMissingWords'
                });
                if(checkQuest.length>0) $('#selectMissing-'+index).addClass('isRepeated')
                else $('#selectMissing-'+index).removeClass('isRepeated')
        },
        changeSummarizeSpokenText(questionId,index){ //7
            var selectedQuest=this.summarizeSpokenTextQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedSummarizeSpokenTextValue[index] = selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#summarizedSpoken-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='summarizedSpoken'
                });
                if(checkQuest.length>0) $('#summarizedSpoken-'+index).addClass('isRepeated')
                else $('#summarizedSpoken-'+index).removeClass('isRepeated')
        },
        changeWriteFromDectation(questionId,index){ //8
            var selectedQuest=this.writeFromDectationQList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedWriteFromDectationValue[index] = selectedQuest.transcript
                index =$.isNumeric(index)? 'id'+index:index;
                $('#writeDict-'+index).text(selectedQuest.transcript)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='writeFromDict'
                });
                if(checkQuest.length>0) $('#writeDict-'+index).addClass('isRepeated')
                else $('#writeDict-'+index).removeClass('isRepeated')
        },
        isSet(val) {
            if ((val != undefined) && (val != '')){
                return true;
            }
            return false;
        },
        Validate(obj,max) {
            if(this.isSet(obj)) {
                if (obj.length && obj.length > 0) { 
                    return false;
                }
                var counter=1;
                var flag=false
                for (var key in obj) {
                    //counter++;
                    if (hasOwnProperty.call(obj, key)) {
                        if(obj[key] == '' & counter <= max ) 
                        {
                            flag= false;
                        }
                        else if(counter <= max){
                            counter++;
                            flag =true
                        }
                    }
                }
                }
                return flag;    
            },
        onSubmit(){
            this.errorSelection =''
            if(this.$store.state.mock_title == '' | this.$store.state.mock_type == '0' | this.$store.state.mock_title == undefined | this.$store.state.mock_type == undefined){
                this.errorSelection +='Please Enter Mock Test Name and select Type before saving.\n'
            }
            var highLightSummary = this.selectedCorrectSummaryQ;
            if(!this.Validate(highLightSummary, this.questminimumValues.highLightCorrectSummaryMin)){
                this.errorSelection +='Please select the minimum Highlight Correct Summary question Limit: '+ this.questminimumValues.highLightCorrectSummaryMin+'.\n'
                //////console.log(this.errorSelection)
            }

            var highLightWords = this.selectedInCorrectWordsQ;
            if(!this.Validate(highLightWords, this.questminimumValues.inCorrectWordsMin)){
                this.errorSelection +='Please select the minimum Highlight Correct Words question Limit: '+ this.questminimumValues.inCorrectWordsMin+'.\n'
            }

            var mcqSingle = this.selectedMutlipleSingleQ;
            if(!this.Validate(mcqSingle, this.questminimumValues.mcqSingleMin)){
                this.errorSelection +='Please select the minimum Multiple Choice Single question Limit: '+ this.questminimumValues.mcqSingleMin+'.\n'
            }

            var mcqMultiple = this.selectedMutlipleChoiceMultipleQ;
            if(!this.Validate(mcqMultiple, this.questminimumValues.mcqMultipleMin)){
                this.errorSelection +='Please select the minimum Multiple Choice Multiple question Limit: '+ this.questminimumValues.mcqMultipleMin+'.\n'
            }

            var fillBlanks = this.selectedFillinBlanksQ;
            if(!this.Validate(fillBlanks, this.questminimumValues.fillBlanksMin)){
                this.errorSelection +='Please select the minimum Fill in Blanks question Limit: '+ this.questminimumValues.fillBlanksMin+'.\n'
            }

            var selectMissingWords = this.selectedMissingWordsQ;
            if(!this.Validate(selectMissingWords, this.questminimumValues.missingWordsMin)){
                this.errorSelection +='Please select the minimum Select Missing Words question Limit: '+ this.questminimumValues.missingWordsMin+'.\n'
            }

            var summarizedSpoken = this.selectedSummarizeSpokenTextQ;
            if(!this.Validate(summarizedSpoken, this.questminimumValues.summarizedSpokenTextMin)){
                this.errorSelection +='Please select the minimum Summarized Spoken Text question Limit: '+ this.questminimumValues.summarizedSpokenTextMin+'.\n'
            }

            var writeFromDict = this.selectedWriteFromDectationQ;
            if(!this.Validate(writeFromDict, this.questminimumValues.writeFromDictationMin)){
                this.errorSelection +='Please select the minimum Write From Dictation question Limit: '+ this.questminimumValues.writeFromDictationMin+'.\n'
            }
            if(this.errorSelection==''){ 
                var questListeningSelected={
                                    highLightSummaryQuest: highLightSummary,
                                    highLightSummaryValues: this.selectedHighLightCorrectSummaryValue,
                                    qustType1:'highLightSummary',
                                    highLightWordsQuest: highLightWords,
                                    highLightWordsValues: this.selectedInCorrectWordsValue,
                                    questType2:'highLightWords',
                                    mcqSingleQuest: mcqSingle,
                                    mcqSingleValues: this.selectedHighLightCorrectSummaryValue,
                                    questType3: 'listMcqSingle',
                                    mcqMultipleQuest: mcqMultiple,
                                    mcqMultipleValues: this.selectedMutlipleChoiceMultipleValue,
                                    questType4: 'listMcqMultiple',
                                    fillBlanksQuest: fillBlanks,
                                    fillBlanksValues: this.selectedFillinBlanksValue,
                                    questType5: 'listFillBlanks',
                                    selectMissingWordsQuest: selectMissingWords,
                                    selectMissingWordsValue: this.selectedMissingWordsValue,
                                    questType6: 'selectMissingWords',
                                    summarizedSpokenQuest: summarizedSpoken,
                                    summarizedSpokenValues: this.selectedSummarizeSpokenTextValue,
                                    questType7: 'summarizedSpoken',
                                    writeFromDictQuest: writeFromDict,
                                    writeFromDictValues: this.selectedWriteFromDectationValue,
                                    questType8: 'writeFromDict',
                                }
                //////console.log(questListeningSelected);
                this.$store.state.mock_module = 'speaking'
                this.$ls.set('listeningSelected', questListeningSelected)

                //////console.log(this.$ls.get('speakingSelected'));
                //////console.log(this.$ls.get('writingSelected'));
                //////console.log(this.$ls.get('readingSelected'));
                //////console.log(this.$ls.get('listeningSelected'));

                var finalArray = {
                    speaking: this.$ls.get('speakingSelected'),
                    writing: this.$ls.get('writingSelected'),
                    reading: this.$ls.get('readingSelected'),
                    listening: this.$ls.get('listeningSelected'),
                    url:'mocktest/createmocktest',
                    mock_test_id:this.$parent.mockTest.mock_test_id,
                    title:this.$store.state.mock_title,
                    free_paid_ind:this.$store.state.mock_type,
                    speaking_writting_time:'35:00',
                    reading_time:'30:00',
                    listening_time:'30:00'
                }

                this.saveMockTest(finalArray).then(()=>{
                                ////console.log(this.getResponse())
                                this.finalArray = {};
                                this.$parent.createNew();
                                this.$parent.dismissCountDown = this.$parent.dismissSecs
                            });

            }

        },
        loadAllMockQuestions(){
            var vm=this
            this.mockQuestionService('mocktest/allmockquest').then(()=>{
                ////debugger
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
            });
        },
        back(){
            this.$store.state.mock_module = 'reading'
            this.$parent.listeningShow = false
            this.$parent.step= 3
            this.$parent.readingShow = true
        },
        reset(){


            if(this.$ls.get('speakingSelected') != null) this.$ls.remove('speakingSelected')

            if(this.$ls.get('writingSelected') != null) this.$ls.remove('writingSelected')

            if(this.$ls.get('readingSelected') != null) this.$ls.remove('readingSelected')

            if(this.$ls.get('listeningSelected') != null) this.$ls.remove('listeningSelected')

            this.selectedInCorrectWordsQ={
                id1:'',id2:'',id3:''
            };
            this.selectedCorrectSummaryQ={
                id1:'',id2:'',id3:''
            };
            this.selectedMutlipleSingleQ={
                id1:'',id2:'',id3:''
            };
            this.selectedMutlipleChoiceMultipleQ={
                id1:'',id2:'',id3:''
            };
            this.selectedFillinBlanksQ={
                id1:'',id2:'',id3:''
            };
            this.selectedMissingWordsQ={
                id1:'',id2:'',id3:''
            };
            this.selectedSummarizeSpokenTextQ={
                id1:'',id2:'',id3:''
            };
            this.selectedWriteFromDectationQ={
                id1:'',id2:'',id3:'',id4:''
            };
            this.selectedwriteFromDectationQList={
                id1:'',id2:'',id3:'',id4:''
            };

            this.selectedHighLightCorrectSummaryValue={
                val1:'',val2:'',val3:''
            };
            this.selectedInCorrectWordsValue={
                val1:'',val2:'',val3:''
            };
            this.selectedListenMutlipleChoiceSingleValue={
                val1:'',val2:'',val3:''
            };
            this.selectedMutlipleChoiceMultipleValue={
                val1:'',val2:'',val3:''
            };
            this.selectedFillinBlanksValue={
                val1:'',val2:'',val3:''
            };
            this.selectedMissingWordsValue={
                val1:'',val2:'',val3:''
            };
            this.selectedSummarizeSpokenTextValue={
                val1:'',val2:'',val3:''
            };
            this.selectedWriteFromDectationValue={
                id1:'',id2:'',id3:'',id4:''
            }

            this.$store.state.mock_module = 'speaking'
            this.$parent.listeningShow = false
            this.$parent.step= 1
            this.$parent.speakingShow = true

            this.$store.state.mock_title = ''
            this.$store.state.mock_type = ''

        },
        filterArry(question_type,array){
            return array.filter(function (item) {
                                return item.question_module == question_type;
                            });
        },
        changeValues(selectedArray,newArray, key,changeEvent){
            var count =0;
                for (var item in selectedArray) {
                    if(count < newArray.length) {
                        selectedArray[item] = newArray[count][key]
                        changeEvent(newArray[count][key],count+1)
                        count++
                    }
                }
        },
        populateData(questionArray){
                //var questionArray = this.$parent.questionArray
                ////console.log('populate called');
                ////console.log(questionArray);
                var tempArray = this.filterArry('summarizedSpoken',questionArray);
                this.changeValues(this.selectedSummarizeSpokenTextQ,tempArray,'question_id',this.changeSummarizeSpokenText);

                tempArray = this.filterArry('writeFromDict',questionArray);
                this.changeValues(this.selectedWriteFromDectationQ,tempArray,'question_id',this.changeWriteFromDectation);

                tempArray = this.filterArry('selectMissingWords',questionArray);
                this.changeValues(this.selectedMissingWordsQ,tempArray,'question_id',this.changeSelectMissingWords);

                tempArray = this.filterArry('listFillBlanks',questionArray);
                this.changeValues(this.selectedFillinBlanksQ,tempArray,'question_id',this.changeFillinBlanks);

                tempArray = this.filterArry('listMcqMultiple',questionArray);
                this.changeValues(this.selectedMutlipleChoiceMultipleQ,tempArray,'question_id',this.changeMutlipleChoiceMultiple);

                tempArray = this.filterArry('listMcqSingle',questionArray);
                this.changeValues(this.selectedMutlipleSingleQ,tempArray,'question_id',this.changeMutlipleChoiceSingle);

                tempArray = this.filterArry('highLightWords',questionArray);
                this.changeValues(this.selectedInCorrectWordsQ,tempArray,'question_id',this.changeHighlightInCorrectWords);

                tempArray = this.filterArry('highLightSummary',questionArray);
                this.changeValues(this.selectedCorrectSummaryQ,tempArray,'question_id',this.changeHighLightCorrectSum);

        }
    },
    mounted(){
        
    },
    beforeMount(){

        this.mockQuestionService('getAllListeningMockQ/getAll').then((res)=>{
            ////console.log('LOADING LISTENING')
            if(res =='success')
            {
                //////console.log(this.getMockQuestionList())
                var allQuestions= this.getMockQuestionList().data
                ////console.log(allQuestions)
                this.highlightCorrectSummaryQList = allQuestions.HighlightCorrectSummary
                var dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.highlightCorrectSummaryQList.splice(0, 0, dumy);

                this.highlightInCorrectWordsQList = allQuestions.HighlightInCorrectWords
                dumy= {question_id: "", question_para: "", title: "Please Select Question."}
                this.highlightInCorrectWordsQList.splice(0, 0, dumy);

                this.mutlipleChoiceSingleQList = allQuestions.MutlipleChoiceSingle
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.mutlipleChoiceSingleQList.splice(0, 0, dumy);

                this.mutlipleChoiceMultipleQList = allQuestions.MutlipleChoiceMultiple
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.mutlipleChoiceMultipleQList.splice(0, 0, dumy);

                this.fillinBlanksQList = allQuestions.FillinBlanks
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.fillinBlanksQList.splice(0, 0, dumy);

                this.missingWordsQList = allQuestions.SelectMissingWords
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.missingWordsQList.splice(0, 0, dumy);

                this.summarizeSpokenTextQList = allQuestions.SummarizeSpokenText
                dumy= {question_id: "", transcript: "", question_title: "Please Select Question."}
                this.summarizeSpokenTextQList.splice(0, 0, dumy);

                this.writeFromDectationQList = allQuestions.WriteFromDectation
                dumy= {question_id: "", transcript: "", title: "Please Select Question."}
                this.writeFromDectationQList.splice(0, 0, dumy);

                
            }
        }).then(()=>{
            var alreadySelected = this.$ls.get('listeningWriting')
            //console.log(alreadySelected)
                if(alreadySelected != undefined & alreadySelected != null )
                {

                    this.selectedInCorrectWordsQ = alreadySelected.highLightWordsQuest
                    this.selectedCorrectSummaryQ = alreadySelected.highLightSummaryQuest
                    this.selectedMutlipleSingleQ = alreadySelected.mcqSingleQuest
                    this.selectedMutlipleChoiceMultipleQ = alreadySelected.mcqMultipleQuest
                    this.selectedFillinBlanksQ = alreadySelected.fillBlanksQuest
                    this.selectedMissingWordsQ = alreadySelected.selectMissingWordsQuest
                    this.selectedSummarizeSpokenTextQ = alreadySelected.summarizedSpokenQuest
                    this.selectedwriteFromDectationQList = alreadySelected.writeFromDictQuest

                    this.selectedHighLightCorrectSummaryValue = alreadySelected.highLightSummaryValues
                    this.selectedInCorrectWordsValue = alreadySelected.highLightWordsValues
                    this.selectedListenMutlipleChoiceSingleValue = alreadySelected.mcqSingleValues
                    this.selectedMutlipleChoiceMultipleValue = alreadySelected.mcqMultipleValues
                    this.selectedFillinBlanksValue = alreadySelected.fillBlanksValues
                    this.selectedMissingWordsValue = alreadySelected.selectMissingWordsValues
                    this.selectedSummarizeSpokenTextValue = alreadySelected.summarizedSpokenValues
                    this.selectedWriteFromDectationValue = alreadySelected.writeFromDictValues
                }
                else if(this.$parent.getAllQuestionList != null){
                    this.populateData(this.$parent.getAllQuestionList)
                }
        })
        this.loadAllMockQuestions()
    }
}
</script>

<style scoped>
.questHeading {
    padding-bottom: 1rem!important;
    margin-bottom: 1rem!important;
}
.col-3
{
    border-right: 1px solid #000;
}
</style>