<template>
    <div class="admissionForm">
        <b-row>
            <b-col cols="2" class="allMockTest col-2 mt-4">
                <b-list-group >
                    <b-list-group-item  v-for="question in allMockTest" :key="question.mock_test_id" class="d-flex justify-content-between align-items-center" @click="getMockTest(question['mock_test_id'])" >
                        {{question["title"]}}
                        <!-- Used this for user attempts -->
                        
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col cols="10">
                <b-row class="mt-2 mb-4">
                <b-col cols="6">
                    <label for="title">Enter Title</label>
                    <b-form-input v-model="mockTest.title" placeholder="Enter Mock Test Title"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="mock_opt">Select Mock Test Type</label>
                    <b-form-select v-model="mockTest.type" :options="options"></b-form-select>
                </b-col>
                <b-col cols="8">
                    <b-row class="mt-4 ml-2 mr-2">
                        <b-col cols="12">
                            <b-alert
                            :show="dismissCountDown"
                            dismissible
                            variant="success"
                            @dismissed="dismissCountDown=0"
                            
                            >
                            Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                            </b-alert>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="4 mt-4 text-right pr-4">
                    <b-button type="button" variant="success" @click="createNew">Create New Mock</b-button>
                </b-col>
                 <hr/>
            </b-row>
            <b-row v-if="showTabs">
            <b-col lg="12" class="mb-5" >
                <b-progress class="mt-2" :max="max" >
                <b-progress-bar variant="success" :value="25" >
                    Speaking
                </b-progress-bar>
                <b-progress-bar :class="{'pending': step<2}" variant="success" :value="25" >
                    Writing
                </b-progress-bar>
                <b-progress-bar :class="{'pending': step<3}" variant="success" :value="25" >
                    Reading
                </b-progress-bar>
                <b-progress-bar :class="{'pending': step<4}" variant="success" :value="25" >
                    Listening
                </b-progress-bar>
                </b-progress>
            </b-col>
            <b-col :class="{'pending': step<1}" lg="3"></b-col>
            <b-col :class="{'pending': step<2}" lg="3"></b-col>
            <b-col :class="{'pending': step<3}" lg="3"></b-col>
            <b-col :class="{'pending': step<4}" lg="3"></b-col>
            <b-col v-if="speakingStart" v-show="speakingShow" lg="12">
                <app-SpeakingTab ref="refspeaking" :key="speakingKey" v-if="speakingStart" v-show="speakingShow"/>
            </b-col>
            <b-col v-if="writingStart"  v-show="writingShow" lg="12">
                <app-WritingTab v-if="writingStart"  v-show="writingShow"/>
            </b-col>
            <b-col v-if="readingStart"  v-show="readingShow" lg="12">
                <app-ReadingTab v-if="readingStart"  v-show="readingShow"/>
            </b-col>
            <b-col v-if="listeningStart"  v-show="listeningShow" lg="12">
                <app-ListeningTab v-if="listeningStart"  v-show="listeningShow"/>
            </b-col>
            </b-row>
            <b-row v-else></b-row>
            </b-col>
           
        
            
        </b-row>
    </div>
</template>
<script>
import ListeningTab from './mockquestions/listeningselection.vue'
import WritingTab from './mockquestions/writingselection.vue'
import ReadingTab from './mockquestions/readingselection.vue'
import SpeakingTab from './mockquestions/speakingselection.vue'
import { mapActions,mapGetters } from 'vuex'
export default {
    components:{
        'app-ListeningTab': ListeningTab,
        'app-WritingTab': WritingTab,
        'app-ReadingTab': ReadingTab,
        'app-SpeakingTab': SpeakingTab
    },
    data() {
      return {
        max: 100,
        dismissSecs: 5,
        dismissCountDown: 0,
        showTabs: true,
        speakingStart: true,
        writingStart: false,
        readingStart: false,
        listeningStart: false,
        speakingShow: true,
        writingShow: false,
        readingShow: false,
        listeningShow: false,
        speakingKey:1,
        allMockTest:null,
        getAllQuestionList:null,
        step:1,
        mockTest:{
                title:'',
                type:'0',
                mock_test_id:0
            },
        options: [
                { value: '0', text: 'Please select an option' },
                { value: 'Free', text: 'Free Mock Test' },
                { value: 'Paid', text: 'Paid Mock Test' }
            ],
            questSpeakingSelected:{
                                    readQuest: null,
                                    repeatSentenceQuest: null,
                                    describeImageQuest: null,
                                    reTellLectureQuest: null,
                                    answershortQuest: null
                                }
        // personalInformation: null,
        // conatctInformation: null,
        // addressInformation: null,
        // residentInformation: null
      }
    },
    methods:{
        ...mapActions({
            mockQuestionService: 'mocktest/fetchMockQuestion',
            saveMockTest:'mocktest/saveMockTest',
        }),
        ...mapGetters({
            getMockQuestionList: 'mocktest/mockQuestions',
            getResponse: 'mocktest/QuestionResponse'
        }),
        getMockTest(questionId){
            var vm = this;
            vm.createNew()
            this.clearAllMockSession()
            var currentMock=this.allMockTest.filter(function (item) {
                                return item.mock_test_id == questionId;
                            })[0];
            if(currentMock != null)
            {
                this.mockTest.title=currentMock.title
                this.mockTest.mock_test_id = currentMock.mock_test_id
                this.mockTest.type = currentMock.free_paid_ind
            }
            else {
                this.$alert('Unable to load selected Mock Test')
            }
            ////console.log('CURRENT MOCK')
            ////console.log(currentMock)
            this.mockQuestionService('mocktest/id='+questionId).then(()=>{
                        this.getAllQuestionList = this.getMockQuestionList().questions
                        ////console.log(this.getAllQuestionList)
                    })
                    .then(()=>{
                        vm.$refs.refspeaking.populateData(this.getAllQuestionList)
                        
                    });

                    
        },
        createNew(){
        this.showTabs= false
        
        this.clearAllMockSession()
        this.max= 100
        this.speakingStart= true
        this.writingStart= false
        this.readingStart= false
        this.listeningStart= false
        this.speakingShow= true
        this.writingShow= false
        this.readingShow= false
        this.listeningShow= false
        this.getAllQuestionList=null
        this.step=1
        this.mockTest={
                title:'',
                type:'0',
                mock_test_id:0
            }
        this.options=[
                { value: '0', text: 'Please select an option' },
                { value: 'Free', text: 'Free Mock Test' },
                { value: 'Paid', text: 'Paid Mock Test' }
            ]
            this.questSpeakingSelected={
                                    readQuest: null,
                                    repeatSentenceQuest: null,
                                    describeImageQuest: null,
                                    reTellLectureQuest: null,
                                    answershortQuest: null
                                }
         //this.$forceUpdate()
         this.speakingKey++;
         this.showTabs =true
        },
        clearAllMockSession(){
            if(this.$ls.get('speakingSelected') != null) this.$ls.remove('speakingSelected')

            if(this.$ls.get('writingSelected') != null) this.$ls.remove('writingSelected')

            if(this.$ls.get('readingSelected') != null) this.$ls.remove('readingSelected')

            if(this.$ls.get('listeningSelected') != null) this.$ls.remove('listeningSelected')
        }
    },
    watch:{
        mockTest: {
            handler(val){
                this.$store.state.mock_title = val.title
                this.$store.state.mock_type = val.type
            },
        deep: true
        }
    },
    beforeMount(){

        this.mockQuestionService('mocktest/getallmocktest').then((res)=>{
            ////console.log('LOADING ALL MOCK TEST')
            if(res =='success')
                {
                    this.allMockTest= this.getMockQuestionList().data
                    ////console.log(this.allMockTest);
                }
            });

        this.$store.state.mock_test_id = 0
        this.$store.state.mock_title == '';
        this.$store.state.mock_type == '0';
        this.clearAllMockSession();
        this.getAllQuestionList = null;
    }
}
</script>
<style scoped>
.admissionForm
{
    background: #fbfbfb;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.progress
{
    height: 2.5rem;
    border-radius: .5rem;
}
.bg-success {
    background-color: #b91200 !important;
    font-weight: 600;
    font-size: 1.1rem;
    opacity: 1;
    text-transform: uppercase;
}
.pending
{
   background-color: #b91200 !important;
   font-weight: 400;
   opacity: .7;
}

</style>
<style>
.form-control.is-valid , .form-control.is-invalid
{
        background-size: calc(0.5em + 0.3rem)!important;
        padding-right: 2px!important;
}
.form-control.is-invalid
{
     padding-right: 1px!important;
}
.btn-seeAll.formButton, .btn-seeAll.formButton:hover, .btn-seeAll.formButton:active, .btn-seeAll.formButton:focus
{
    padding: 0.4rem 2rem!important;
    font-size: 13px!important;
    font-weight: 601!important;
    display: inline-block;
    position: relative;
    float: none!important;
}
.btn-secondary.btn-seeAll.formButton
{
    margin-right: 20px;
    background-color: #f25a69!important;
border-color: #f25a69!important;
}
 .custom-control-input.is-valid ~ .custom-control-label {
color: #212529!important;
}
.admissionForm .pre-formatted {
  white-space: pre;
}
.admissionForm .btn{
    text-transform: uppercase;
    padding: 8px 25px!important;
}
.admissionForm .btn-danger, .btn-warning{
    
    width: 100%;
}
</style>