<template>
   <b-row>
       <!-- <b-form class="col-12"  v-on:submit.prevent="onSubmit" > -->
       
        <b-col cols="12" v-if="mcqSingleQuestList!=null">
            <h5>Multiple Choice (Single) Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedMCQSingleQuest" :key="item[index]" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedMCQSingleQuest[index]" :options="mcqSingleQuestList" @change="changeMCQSingle(selectedMCQSingleQuest[index],index)" value-field="mcs_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'mcqSingle-'+index" cols="9">
                    {{selectedMCQSingleValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="mcqMultipleQuestList!=null">
            <h5>Multiple Choice (Multiple) Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedMCQMultipleQuest" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedMCQMultipleQuest[index]" :options="mcqMultipleQuestList" @change="changeMCQMultiple(selectedMCQMultipleQuest[index],index)" value-field="mcs_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'mcqMultiple-'+index" cols="9">
                    {{selectedMCQMultipleValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="reOrderParaQuestList!=null">
            <h5>Re-Order Paragraph Questions</h5>
            <p class="questHeading">Please add 2 to 3 questions.</p>
            <b-row v-for="(item,index) in selectedReOrderParaQuest" :key="item[index]"  class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedReOrderParaQuest[index]" :options="reOrderParaQuestList" @change="changeReOrderPara(selectedReOrderParaQuest[index],index)"  value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'reOrderPara-'+index" cols="9">
                    {{selectedReOrderParaValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="readFillQuestList!=null">
            <h5>Reading Fill in Blanks Questions</h5>
            <p class="questHeading">Please add 4 to 5 questions.</p>
            <b-row v-for="(item,index) in selectedReadFillQuest" :key="item[index]" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedReadFillQuest[index]" :options="readFillQuestList" @change="changeReadFill(selectedReadFillQuest[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'readFill-'+index" cols="9">
                    {{selectedReadFillValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="readWriteFillQuestList!=null">
            <h5>Read & Write Fill in Blanks Questions</h5>
            <p class="questHeading">Please add 5 to 6 questions.</p>
            <b-row v-for="(item,index) in selectedReadWriteFillQuest" :key="item[index]" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedReadWriteFillQuest[index]" :options="readWriteFillQuestList" @change="changeReadWriteFill(selectedReadWriteFillQuest[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'readWriteFill-'+index" cols="9">
                    {{selectedReadWriteFillValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="errorSelection!=''">
            <p class="pre-formatted text-danger mb-5">{{errorSelection}}</p>
        </b-col>
        <div class="col-6 displayInline" align="right">
            <b-button type="button" variant="primary" @click="onSubmit">{{buttonText}}</b-button>
        </div>
        <!-- <div class="col-2 displayInline" align="center">
            <b-button type="button" @click="reset()" variant="danger">Reset</b-button>
        </div> -->
        <div class="col-2 displayInline" align="left">
            <b-button type="button" @click="back()" variant="outline-warning">Back</b-button>
        </div>
        <!-- </b-form> -->
    </b-row>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery';
export default {
    data(){
        return{
            mcqSingleQuestList:null,
            mcqMultipleQuestList:null,
            reOrderParaQuestList:null,
            readFillQuestList:null,
            readWriteFillQuestList:null,
            selectedMCQSingleQuest:{
                id1:'',id2:'',id3:''
            },
            selectedMCQMultipleQuest:{
                id1:'',id2:'',id3:''
            },
            selectedReOrderParaQuest:{
                id1:'',id2:'',id3:''
            },
            selectedReadFillQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:''
            },
            selectedReadWriteFillQuest:{
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:''
            },
            selectedMCQSingleValue:{
                val1:'',val2:'',val3:''
            },
            selectedMCQMultipleValue:{
                val1:'',val2:'',val3:''
            },
            selectedReOrderParaValue:{
                val1:'',val2:'',val3:''
            },
            selectedReadFillValue:{
                val1:'',val2:'',val3:'',val4:'',val5:''
            },
            selectedReadWriteFillValue:{
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:''
            },
             buttonText: 'Save Reading Module & Next',
            questminimumValues:{
                mcqSingleMin:1, mcqMultipleMin:1, reOrderParaMin: 2, readFillBlanksMin: 4, readWriteFillBlanksMin: 5
            },
            errorSelection:'',
            allmockQuestions:null
        }
    },
    methods:{
        ...mapActions({
            mockQuestionService: 'mocktest/fetchMockQuestion',
            saveMockTest:'mocktest/saveMockTest',
        }),
        ...mapGetters({
            getMockQuestionList: 'mocktest/mockQuestions',
            getResponse: 'mocktest/QuestionResponse'
        }),
        changeMCQSingle(questionId,index){
            var selectedQuest=this.mcqSingleQuestList.filter(function (item) {
                    return item.mcs_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedMCQSingleValue[index]=selectedQuest.msc_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#mcqSingle-'+index).text(selectedQuest.msc_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='mcqSingle'
                });
                if(checkQuest.length>0) $('#mcqSingle-'+index).addClass('isRepeated')
                else $('#mcqSingle-'+index).removeClass('isRepeated')
        },
        changeMCQMultiple(questionId,index){
            var selectedQuest=this.mcqMultipleQuestList.filter(function (item) {
                    return item.mcs_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedMCQMultipleValue[index]=selectedQuest.msc_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#mcqMultiple-'+index).text(selectedQuest.msc_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='mcqMultiple'
                });
                if(checkQuest.length>0) $('#mcqMultiple-'+index).addClass('isRepeated')
                else $('#mcqMultiple-'+index).removeClass('isRepeated')
        },
        changeReOrderPara(questionId,index){
            var selectedQuest=this.reOrderParaQuestList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedReOrderParaValue[index] = selectedQuest.reorder_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#reOrderPara-'+index).text(selectedQuest.reorder_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='reOrderPara'
                });
                if(checkQuest.length>0) $('#reOrderPara-'+index).addClass('isRepeated')
                else $('#reOrderPara-'+index).removeClass('isRepeated')
        },
        changeReadFill(questionId,index){
            var selectedQuest=this.readFillQuestList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedReadFillValue[index] = selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#readFill-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='readFill'
                });
                if(checkQuest.length>0) $('#readFill-'+index).addClass('isRepeated')
                else $('#readFill-'+index).removeClass('isRepeated')
        },
        changeReadWriteFill(questionId,index){
            var selectedQuest=this.readWriteFillQuestList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedReadWriteFillValue[index] = selectedQuest.question_para
                index =$.isNumeric(index)? 'id'+index:index;
                $('#readWriteFill-'+index).text(selectedQuest.question_para)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='readWriteFill'
                });
                if(checkQuest.length>0) $('#readWriteFill-'+index).addClass('isRepeated')
                else $('#readWriteFill-'+index).removeClass('isRepeated')
        },
        isSet(val) {
            if ((val != undefined) && (val != '')){
                return true;
            }
            return false;
        },
        Validate(obj,max) {
            if(this.isSet(obj)) {
                if (obj.length && obj.length > 0) { 
                    return false;
                }
                var counter=1;
                var flag=false
                for (var key in obj) {
                    //counter++;
                    if (hasOwnProperty.call(obj, key)) {
                        if(obj[key] == '' & counter <= max ) 
                        {
                            flag= false;
                        }
                        else if(counter <= max){
                            counter++;
                            flag =true
                        }
                    }
                }
                }
                return flag;    
            },
        onSubmit(){
            this.errorSelection =''
            if(this.$store.state.mock_title == '' | this.$store.state.mock_type == '0' | this.$store.state.mock_title == undefined | this.$store.state.mock_type == undefined){
                this.errorSelection +='Please Enter Mock Test Name and select Type before saving.\n'
            }
            var mcqSingle = this.selectedMCQSingleQuest
            if(!this.Validate(mcqSingle,this.questminimumValues.mcqSingleMin)){
                this.errorSelection +='Please select the minimum MCQ Single question Limit: '+ this.questminimumValues.mcqSingleMin+'.\n'
                //////console.log(this.errorSelection)
            }

            var mcqMultiple = this.selectedMCQMultipleQuest;
            if(!this.Validate(mcqMultiple,this.questminimumValues.mcqMultipleMin)){
                this.errorSelection +='Please select the minimum MCQ Multiple question Limit: '+ this.questminimumValues.mcqMultipleMin+'.\n'
            }

            var reOrderPara = this.selectedReOrderParaQuest;
            if(!this.Validate(reOrderPara,this.questminimumValues.reOrderParaMin)){
                this.errorSelection +='Please select the minimum Re-Order Paragraph question Limit: '+ this.questminimumValues.reOrderParaMin+'.\n'
            }

            var readFill = this.selectedReadFillQuest;
            if(!this.Validate(readFill,this.questminimumValues.readFillBlanksMin)){
                this.errorSelection +='Please select the minimum Reading Fill in Blanks question Limit: '+ this.questminimumValues.readFillBlanksMin+'.\n'
            }

            var readWriteFill = this.selectedReadWriteFillQuest;
            if(!this.Validate(readWriteFill,this.questminimumValues.readWriteFillBlanksMin)){
                this.errorSelection +='Please select the minimum Read & Write Fill in Blanks question Limit: '+ this.questminimumValues.readWriteFillBlanksMin+'.\n'
            }
            if(this.errorSelection==''){ 
                var questReadingSelected={
                                    mcqSingleQuest: mcqSingle,
                                    mcqSingleValue: this.selectedMCQSingleValue,
                                    qustType1:'mcqSingle',
                                    mcqMultipleQuest: mcqMultiple,
                                    mcqMultipleValue: this.selectedMCQMultipleValue,
                                    questType2:'mcqMultiple',
                                    reOrderParaQuest: reOrderPara,
                                    reOrderParaValues: this.selectedReOrderParaValue,
                                    questType3: 'reOrderPara',
                                    readFillQuest: readFill,
                                    readFillValues : this.selectedReadFillValue,
                                    questType4: 'readFill',
                                    readWriteFillQuest: readWriteFill,
                                    readWriteFillValues: this.readWriteFillValues,
                                    questType5: 'readWriteFill'
                                }
                //////console.log(questReadingSelected);
                this.$store.state.mock_module = 'listening'
                this.$ls.set('readingSelected', questReadingSelected)
                this.$parent.listeningStart = true
                this.$parent.listeningShow = true
                this.$parent.step= 4
                this.$parent.readingShow = false


            }
        },
        back(){
            this.$store.state.mock_module = 'writing'
            this.$parent.writingShow = true
            this.$parent.step= 2
            this.$parent.readingShow = false
        },
        reset(){
            this.selectedMCQSingleQuest={
                id1:'',id2:'',id3:''
            };
            this.selectedMCQMultipleQuest={
                id1:'',id2:'',id3:''
            };
            this.selectedReOrderParaQuest={
                id1:'',id2:'',id3:''
            };
            this.selectedReadFillQuest={
                id1:'',id2:'',id3:'',id4:'',id5:''
            };
            this.selectedReadWriteFillQuest={
                id1:'',id2:'',id3:'',id4:'',id5:'',id6:''
            };
            this.selectedMCQSingleValue={
                val1:'',val2:'',val3:''
            };
            this.selectedMCQMultipleValue={
                val1:'',val2:'',val3:''
            };
            this.selectedReOrderParaValue={
                val1:'',val2:'',val3:''
            };
            this.selectedReadFillValue={
                val1:'',val2:'',val3:'',val4:'',val5:''
            };
            this.selectedReadWriteFillValue={
                val1:'',val2:'',val3:'',val4:'',val5:'',val6:''
            }
        },
        filterArry(question_type,array){
            return array.filter(function (item) {
                                return item.question_module == question_type;
                            });
        },
        changeValues(selectedArray,newArray, key,changeEvent){
            var count =0;
                for (var item in selectedArray) {
                    if(count < newArray.length) {
                        selectedArray[item] = newArray[count][key]
                        changeEvent(newArray[count][key],count+1)
                        count++
                    }
                }
        },
        loadAllMockQuestions(){
            var vm=this
            this.mockQuestionService('mocktest/allmockquest').then(()=>{
                ////debugger
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
            });
        },
        populateData(questionArray){
                //var questionArray = this.$parent.questionArray
                ////console.log('populate called');
                ////console.log(questionArray);
                var tempArray = this.filterArry('mcqSingle',questionArray);

                this.changeValues(this.selectedMCQSingleQuest,tempArray,'question_id',this.changeMCQSingle);

                tempArray = this.filterArry('mcqMultiple',questionArray);
                this.changeValues(this.selectedMCQMultipleQuest,tempArray,'question_id',this.changeMCQMultiple);

                tempArray = this.filterArry('reOrderPara',questionArray);
                this.changeValues(this.selectedReOrderParaQuest,tempArray,'question_id',this.changeReOrderPara);

                tempArray = this.filterArry('readFill',questionArray);
                this.changeValues(this.selectedReadFillQuest,tempArray,'question_id',this.changeReadFill);

                tempArray = this.filterArry('readWriteFill',questionArray);
                this.changeValues(this.selectedReadWriteFillQuest,tempArray,'question_id',this.changeReadWriteFill);

        }
    },
    beforeMount(){
        this.mockQuestionService('getAllReadingMockQ/getAll').then((res)=>{
            ////console.log('LOADING READING')
            if(res =='success')
            {
                //////console.log(this.getMockQuestionList())
                var allQuestions= this.getMockQuestionList().data
                ////console.log(allQuestions)
                this.mcqSingleQuestList = allQuestions.MutlipleChoiceSingle
                var dumy= {mcs_id: "", msc_desc: "", title: "Please Select Question."}
                this.mcqSingleQuestList.splice(0, 0, dumy);

                this.mcqMultipleQuestList = allQuestions.MutlipleChoiceMultiple
                dumy= {mcs_id: "", msc_desc: "", title: "Please Select Question."}
                this.mcqMultipleQuestList.splice(0, 0, dumy);

                this.reOrderParaQuestList = allQuestions.ReOrderPara
                dumy= {question_id: "", reorder_desc: "", title: "Please Select Question."}
                this.reOrderParaQuestList.splice(0, 0, dumy);

                this.readFillQuestList = allQuestions.ReadFillBlanks
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.readFillQuestList.splice(0, 0, dumy);
                
                this.readWriteFillQuestList = allQuestions.ReadWriteFillinBlanks
                dumy= {question_id: "", question_para: "", title: "Please Select Question."}
                this.readWriteFillQuestList.splice(0, 0, dumy);
                
            }
        }).then(()=>{
            var alreadySelected = this.$ls.get('readingSelected')
                if(alreadySelected != undefined & alreadySelected != null )
                {
                    this.selectedMCQSingleQuest = alreadySelected.mcqSingleQuest
                    this.selectedMCQMultipleQuest = alreadySelected.mcqMultipleQuest
                    this.selectedReOrderParaQuest = alreadySelected.reOrderParaQuest
                    this.selectedReadFillQuest = alreadySelected.readFillQuest
                    this.selectedReadWriteFillQuest = alreadySelected.readWriteFillQuest

                    this.selectedMCQSingleValue = alreadySelected.mcqSingleValue
                    this.selectedMCQMultipleValue = alreadySelected.mcqMultipleValue
                    this.selectedReOrderParaValue = alreadySelected.reOrderParaValues
                    this.selectedReadFillValue = alreadySelected.readFillValues
                    this.selectedReadWriteFillValue = alreadySelected.readWriteFillValues
                }
                else if(this.$parent.getAllQuestionList != null){
                    this.populateData(this.$parent.getAllQuestionList)
                }
        })
        this.loadAllMockQuestions()
    },
     mounted(){
        
    }
}
</script>
<style scoped>
.questHeading {
    padding-bottom: 1rem!important;
    margin-bottom: 1rem!important;
}
.col-3
{
    border-right: 1px solid #000;
}
</style>