<template>
        <b-row>
         <!-- <b-form class="col-12"  v-on:submit.prevent="onSubmit" > -->
        
        <b-col cols="12" v-if="summarizedQuestList!=null">
            <h5>Summarized Written Text Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedSummarizedQuest" :key="item[index]" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedSummarizedQuest[index]" :options="summarizedQuestList" @change="changeSummarized(selectedSummarizedQuest[index],index)" value-field="written_text_id" 
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'summarized-'+index" cols="9">
                    {{selectedSummarizedValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="essayQuestList!=null">
            <h5>Write Essay Questions</h5>
            <p class="questHeading">Please add 1 to 2 questions.</p>
            <b-row v-for="(item,index) in selectedEssayQuest" :key="item[index]" class="mb-4">
                <b-col cols="3">
                    <b-form-select v-model="selectedEssayQuest[index]" :options="essayQuestList" @change="changeEssay(selectedEssayQuest[index],index)" value-field="question_id"
                        text-field="title"></b-form-select>
                </b-col>
                <b-col :id="'essay-'+index" cols="9">
                    {{selectedEssayValue[index]}}
                </b-col>
            </b-row>
            <hr/>
        </b-col>
        <b-col cols="12" v-if="errorSelection!=''">
            <p class="pre-formatted text-danger mb-5">{{errorSelection}}</p>
        </b-col>
        <div class="col-6 displayInline" align="right">
            <b-button type="button" variant="primary" @click="onSubmit()">{{buttonText}}</b-button>
        </div>
         <!-- <div class="col-2 displayInline" align="center">
            <b-button type="button" @click="reset()" variant="danger">Reset</b-button>
        </div> -->
        <div class="col-2 displayInline" align="left">
            <b-button type="button" @click="back()" variant="outline-warning">Back</b-button>
        </div>
        <!-- </b-form> -->
    </b-row>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import $ from 'jquery'
export default {
    data(){
        return{
            allmockQuestions:null,
            test:'',
            summarizedQuestList:null,
            essayQuestList:null,
            selectedSummarizedQuest:{
                id1:'',id2:'',id3:''
            },
            selectedEssayQuest:{
                id1:'',id2:''
            },
            selectedSummarizedValue:{
                val1:'',val2:'',val3:''
            },
            selectedEssayValue:{
                val1:'',val2:''
            },
            buttonText: 'Save Writing Module & Next',
            questminimumValues:{
                summarizedMin:1, essayMin:1
            },
            errorSelection:''
        }
    },
    methods:{
        ...mapActions({
            mockQuestionService: 'mocktest/fetchMockQuestion',
            saveMockTest:'mocktest/saveMockTest',
        }),
        ...mapGetters({
            getMockQuestionList: 'mocktest/mockQuestions',
            getResponse: 'mocktest/QuestionResponse'
        }),
        changeSummarized(questionId,index){
            var selectedQuest=this.summarizedQuestList.filter(function (item) {
                    return item.written_text_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedSummarizedValue[index]=selectedQuest.written_text
                index =$.isNumeric(index)? 'id'+index:index;
                ////console.log(selectedQuest.written_text)
                ////console.log($('#summarized-'+index))
                $('#summarized-'+index).text(selectedQuest.written_text)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='summarized'
                });
                if(checkQuest.length>0) $('#summarized-'+index).addClass('isRepeated')
                else $('#summarized-'+index).removeClass('isRepeated')
        },
        changeEssay(questionId,index){
            //////console.log(questionId + '-'+index)
            ////console.log(this.essayQuestList)
            var selectedQuest=this.essayQuestList.filter(function (item) {
                    return item.question_id == questionId;
                })[0];
                ////console.log(selectedQuest)
                this.selectedEssayValue[index]=selectedQuest.question_desc
                index =$.isNumeric(index)? 'id'+index:index;
                $('#essay-'+index).text(selectedQuest.question_desc)
                const checkQuest=this.allmockQuestions.filter((item)=>{
                return item.question_id == questionId & item.question_module=='essay'
                });
                if(checkQuest.length>0) $('#essay-'+index).addClass('isRepeated')
                else $('#essay-'+index).removeClass('isRepeated')
        },
        isSet(val) {
            if ((val != undefined) && (val != '')){
                return true;
            }
            return false;
        },
        Validate(obj,max) {
            if(this.isSet(obj)) {
                if (obj.length && obj.length > 0) { 
                    return false;
                }
                var counter=1;
                var flag=false
                for (var key in obj) {
                    //counter++;
                    if (hasOwnProperty.call(obj, key)) {
                        if(obj[key] == '' & counter <= max ) 
                        {
                            flag= false;
                        }
                        else if(counter <= max){
                            counter++;
                            flag =true
                        }
                    }
                }
                }
                return flag;    
            },
        onSubmit(){
            this.errorSelection =''
            if(this.$store.state.mock_title == '' | this.$store.state.mock_type == '0' | this.$store.state.mock_title == undefined | this.$store.state.mock_type == undefined){
                this.errorSelection +='Please Enter Mock Test Name and select Type before saving.\n'
            }
            var summarized = this.selectedSummarizedQuest;
            if(!this.Validate(summarized, this.questminimumValues.summarizedMin)){
                this.errorSelection +='Please select the minimum Summarized Text question Limit: '+ this.questminimumValues.summarizedMin+'.\n'
                //////console.log(this.errorSelection)
            }

            var essay = this.selectedEssayQuest;
            if(!this.Validate(essay, this.questminimumValues.essayMin)){
                this.errorSelection +='Please select the minimum Repeat Sentence question Limit: '+ this.questminimumValues.essayMin+'.\n'
            }
            ////console.log('before validation')
            if(this.errorSelection==''){ 
                var questWritingSelected={
                                    summarizedQuest: summarized,
                                    summarizedValues: this.selectedSummarizedValue,
                                    qustType1:'summarized',
                                    essayQuest: essay,
                                    essayValue: this.selectedEssayValue,
                                    questType2:'essay',
                                    url:'mocktest/createmocktest'
                                }
                                ////console.log('after validation')
                ////console.log(questWritingSelected);
                this.$store.state.mock_module = 'reading'
                this.$ls.set('writingSelected', questWritingSelected)
                this.$parent.writingShow = false
                this.$parent.readingStart = true
                this.$parent.readingShow = true
                this.$parent.step= 3
                // this.saveMockTest(questWritingSelected).then(()=>{
                //                 ////console.log(this.getResponse())
                //                 this.$store.state.mock_test_id = this.getResponse().data
                //                 this.questWritingSelected = {};
                //                 this.$store.state.mock_module = 'reading'
                //                 //alert('Data has been saved successfully.');
                //             });

            }
        },
        loadAllMockQuestions(){
            var vm=this
            this.mockQuestionService('mocktest/allmockquest').then(()=>{
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
            });
        },
        back(){
            this.$store.state.mock_module = 'speaking'
            this.$parent.writingShow = false
            this.$parent.step= 1
            this.$parent.speakingShow = true
        },
        reset(){
            this.selectSummarizedQuest={
                id1:'',id2:'',id3:''
            }
            this.selectEssayQuest={
                id1:'',id2:''
            }
            this.selectSummarizedValue={
                val1:'',val2:'',val3:''
            }
            this.selectEssayValue={
                val1:'',val2:''
            }
        },
        filterArry(question_type,array){
            return array.filter(function (item) {
                                return item.question_module == question_type;
                            });
        },
        changeValues(selectedArray,newArray, key,changeEvent){
            var count =0;
                for (var item in selectedArray) {
                    if(count < newArray.length) {
                        ////console.log(newArray[count])
                        selectedArray[item] = newArray[count][key]
                        changeEvent(newArray[count][key],count+1)
                        count++
                    }
                }
        },
        populateData(questionArray){
                //var questionArray = this.$parent.questionArray
                ////console.log('populate called');
                ////console.log(questionArray);
                ////console.log(this.filterArry('summarized',questionArray));
                var tempArray = this.filterArry('summarized',questionArray);

                this.changeValues(this.selectedSummarizedQuest,tempArray,'question_id',this.changeSummarized);

                tempArray = this.filterArry('essay',questionArray);
                this.changeValues(this.selectedEssayQuest,tempArray,'question_id',this.changeEssay);
        }
    },
    beforeMount(){
        var vm=this
        this.mockQuestionService('mocktest/allmockquest').then(()=>{
                    vm.allmockQuestions=vm.getMockQuestionList().questions
                    //console.log(vm.allmockQuestions)
            
        this.mockQuestionService('getAllWritingMockQ/getAll').then((res)=>{
            ////console.log('LOADING WRITING')
            if(res =='success')
            {
                //////console.log(this.getMockQuestionList())
                var allQuestions= this.getMockQuestionList().data
                ////console.log(allQuestions)
                this.summarizedQuestList = allQuestions.summarizeWrittenText
                var dumy= {written_text_id: "", written_text: "", title: "Please Select Question."}
                this.summarizedQuestList.splice(0, 0, dumy);
                this.essayQuestList = allQuestions.writeEssay
                dumy= {question_id: "", question_desc: "", title: "Please Select Question."}
                this.essayQuestList.splice(0, 0, dumy);
                
            }
        }).then(()=>{
             
            var alreadySelected = this.$ls.get('selectedWriting')
                if(alreadySelected != undefined & alreadySelected != null)
                {
                    this.selectedSummarizedQuest = alreadySelected.summarizedQuest
                    this.selectedEssayQuest = alreadySelected.essayQuest

                    this.selectedSummarizedValue = alreadySelected.summarizedValues
                    this.selectedEssayValue = alreadySelected.essayValue
                }
                else if(this.$parent.getAllQuestionList != null){
                    this.populateData(this.$parent.getAllQuestionList)
                }
        })
        });
    }
}
</script>
<style scoped>
.questHeading {
    padding-bottom: 1rem!important;
    margin-bottom: 1rem!important;
}
.col-3
{
    border-right: 1px solid #000;
}
</style>